import { number } from "echarts"

export const userAddFormRulesMixin = {
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/ // 验证邮箱的正则表达式
      if (regEmail.test(value)) {
        return callback() // 合法邮箱
      }
      // 返回一个错误提示
      callback(new Error('请输入合法的邮箱'))
    }
    // 验证手机的规则
    var checkMobeli = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return callback()
      }
      // 返回一个错误提示
      callback(new Error('请输入合法的手机号码'))
    }
    return {
      // 添加表单的验证规则对象
      addFormRules: {
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '用户名长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机', trigger: 'blur' },
          { validator: checkMobeli, trigger: 'blur' }
        ]
      },
      // 修改用户数据验证规则
      editFormRules: {
        email: [
          { required: true, message: '请输入用户邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入用户手机', trigger: 'blur' },
          { validator: checkMobeli, trigger: 'blur' }
        ]
      },
      extractFormRules:{
        extract: [
          { required: true, message: '请输入提取金额', trigger: 'blur' },
        ],
      }
    }
  }
}
export const rolesFormRulesMixin = {
  data() {
    return {
      addFormRules: {
        name: [
          { required: true, message: '请输入角色名字', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '输入的范围是 3 ~ 10 为字符',
            triggetr: 'nlur'
          }
        ],
        desc: [
          { required: true, message: '请输入角色描述', trigger: 'blur' },
          {
            min: 5,
            max: 20,
            message: '输入的范围是 5 ~ 20 为字符',
            triggetr: 'nlur'
          }
        ]
      },
      editFormRules: {
        roleName: [
          { required: true, message: '请输入角色名字', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '输入的范围是 3 ~ 10 为字符',
            triggetr: 'nlur'
          }
        ],
        roleDesc: [
          { required: true, message: '请输入角色描述', trigger: 'blur' },
          {
            min: 5,
            max: 20,
            message: '输入的范围是 5 ~ 20 为字符',
            triggetr: 'nlur'
          }
        ]
      }
    }
  }
}
export const paramsFormRulesMixin = {
  data() {
    return {
      // 添加表单的验证规则
      addFormRules: {
        attr_name: [
          { required: true, message: '请输入添加的分类', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      },
      // 修改表单的验证
      editFormRules: {
        attr_name: [
          { required: true, message: '请输入修改的信息', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      }
    }
  }
}
export const goodsAddFormRulesMixin = {
  data() {
    return {
      // 添加艺术品验证规则
      addFormRules: {
        // series_id: [{ required: true, message: '请指定艺术品所属系列', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入艺术品的名称', trigger: 'blur' },
          {
            min: 1,
            max: 100,
            message: '请输入 1 ~ 100 范围的字符',
            trigger: 'blur'
          }
        ],
        abstract: [
          { required: true, message: '请输入艺术品摘要', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入艺术品的描述', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请输入艺术品的价格', trigger: 'blur' }
        ],
        // img_url: [{ required: true, message: '艺术品图不能为空哦~', trigger: 'blur' }],
        artist_id: [{ required: true, message: '请指定艺术品所属艺术家', trigger: 'blur' }],
        category_id: [{ required: true, message: '请指定艺术品所在分类', trigger: 'blur' }],
        last_entity_number:[{ required: true, message: '请输入艺术品发行版数', trigger: 'blur' }],
        chain_info_id: [{ required: true, message: '请指定艺术品所在公链', trigger: 'blur' }],
        show_form: [{ required: true, message: '请选择艺术品展现形式', trigger: 'blur' }],
        sold_mode: [{ required: true, message: '请选择艺术品所属类型', trigger: 'blur' }],
      }
    }
  }
}

export const artistsAddFormRulesMixin = {
  data() {
    return {
      // 添加艺术家验证规则
      addArtistFormRules: {
        nickname: [
          { required: true, message: '请输入艺术家的昵称', trigger: 'blur' },
          {
            min: 2,
            max: 100,
            message: '请输入 2 ~ 100 范围的字符',
            trigger: 'blur'
          }
        ],
        desc: [
          { required: true, message: '请输入商品的描述', trigger: 'blur' },
          {
            min: 2,
            max: 200,
            message: '请输入 2 ~ 200 范围的字符',
            trigger: 'blur'
          }
        ],
        title: [
          { required: true, message: '请输入艺术家头衔', trigger: 'blur' },
          {
            min: 2,
            max: 500,
            message: '请输入 2 ~ 500 范围的字符',
            trigger: 'blur'
          }
        ],
      }
    }
  }
}

export const artworkAddFormRulesMixin = {
  data() {
    return {
      // 添加艺术家验证规则
      addArtworkFormRules: {
        p_name: [
          { required: true, message: '请输入艺术家的昵称', trigger: 'blur' },
          {
            min: 2,
            max: 100,
            message: '请输入 2 ~ 100 范围的字符',
            trigger: 'blur'
          }
        ],
        p_desc: [
          { required: true, message: '请输入商品的描述', trigger: 'blur' },
          {
            min: 2,
            max: 200,
            message: '请输入 2 ~ 200 范围的字符',
            trigger: 'blur'
          }
        ],
        p_author: [
          { required: true, message: '请输入商品的描述', trigger: 'blur' },
          {
            min: 2,
            max: 100,
            message: '请输入 2 ~ 100 范围的字符',
            trigger: 'blur'
          }
        ],
      }
    }
  }
}

export const ruleAddFormRulesMixin = {
  data() {
    return {
      // 添加艺术家验证规则
      addRuleFormRules: {
        rule_name: [
          { required: true, message: '请输入规名称', trigger: 'blur' },
          {
            min: 2,
            max: 50,
            message: '请输入 2 ~ 50 范围的字符',
            trigger: 'blur'
          }
        ],
        cycle_period: [
          { required: true, message: '请输入循环周期', trigger: 'blur' },
          { type: "number", min:3600 , message: '必须输入一个整数,且大于3600(1小时)', trigger: 'blur' },
        ],
        yield_rate: [
          { required: true, message: '请输入收益率', trigger: 'blur' },
          { type: "number", message: '必须输入一个整数', trigger: 'blur' },
        ],
        init_price: [
          { required: true, message: '请输入初始价格', trigger: 'blur' },
          { type: "number", message: '必须输入一个整数', trigger: 'blur' },
        ],
        min_price: [
          { required: true, message: '请输入最低价格', trigger: 'blur' },
          { type: "number", message: '必须输入一个整数', trigger: 'blur' },
        ],
        max_price: [
          { required: true, message: '请输入最高价格', trigger: 'blur' },
          { type: "number", message: '必须输入一个整数', trigger: 'blur' },
        ],
        ticket: [
          { required: true, message: '请输入消耗卷消耗张数', trigger: 'blur' },
          { type: "number", message: '必须输入一个整数', trigger: 'blur' },
        ],
        pid: [
          { required: true, message: '请选择要绑定的作品', trigger: 'blur' },
        ],
      }
    }
  }
}

export const billAddFormRulesMixin = {
  data() {
    return {
      // 添加艺术家验证规则
      addBillFormRules: {
        rule_id: [
          { required: true, message: '规则不能为空', trigger: 'blur' },
          { type: "number" , message: '必须输入一个整数', trigger: 'blur' },
        ],
        uid: [
          { required: true, message: '初始运营不能为空', trigger: 'blur' },
          { type: "number" , message: '必须输入一个整数', trigger: 'blur' },
        ],
      }
    }
  }
}

export const faqAddFormRulesMixin = {
  data() {
    return {
      // 添加问答
      addFaqFormRules: {
        title: [
          { required: true, message: '问题标题不能为空', trigger: 'blur' },
        ],
        answer: [
          { required: true, message: '问题答案不能为空', trigger: 'blur' },
        ],
      }
    }
  }
}

export const kvAddFormRulesMixin = {
  data() {
    return {
      // 添加
      addFaqFormRules: {
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      }
    }
  }
}

export const operatorAddFormRulesMixin = {
  data() {
    return {
      // 
      addOperatorFormRules: {
        uid: [
          { required: true, message: '用户不能为空', trigger: 'blur' },
        ]
      }
    }
  }
}

export const addRotemapFormRulesMixin = {
  data() {
    return {
      // 添加轮播图
      addRotemapFormRules: {
        link: [
          { required: true, message: '链接不能为空', trigger: 'blur' },
          {
            min: 2,
            max: 250,
            message: '请输入 2 ~ 500 范围的字符',
            trigger: 'blur'
          }
        ],
        desc: [
          { required: true, message: '描述不能为空', trigger: 'blur' },
          {
            min: 2,
            max: 250,
            message: '请输入 2 ~ 250 范围的字符',
            trigger: 'blur'
          }
        ]
      }
    }
  }
}

export const noticeAddFormRulesMixin = {
  data() {
    return {
      // 添加问答
      addNoticeFormRules: {
        title: [
          { required: true, message: '通知标题不能为空', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '通知内容不能为空', trigger: 'blur' },
        ],
        link: [
          { required: true, message: '通知链接不能为空', trigger: 'blur' },
        ],
      }
    }
  }
}

export const syntheticFormRulesMixin = {
  data() {
    return {
      // 添加问答
      addFormRules: {
        target_id: [
          { required: true, message: '目标艺术品不能为空', trigger: 'blur' },
        ],
        classify: [
          { required: true, message: '所需类型不能为空', trigger: 'blur' },
        ],
        is_destroy: [
          { required: true, message: '材料是否销毁不能为空', trigger: 'blur' },
        ],
        // number: [
        //   { required: true, message: '所需数量不能为空', trigger: 'blur' },
        // ],
        factor: [
          { required: true, message: '概率不能为空', trigger: 'blur' },
        ],
      }
    }
  }
}

export const seriesFormRulesMixin = {
  data() {
    return {
      // 添加问答
      addFormRules: {
        name: [
          { required: true, message: '系列名称不能为空', trigger: 'blur' },
        ],
        artist_id: [
          { required: true, message: '艺术家不能为空', trigger: 'blur' },
        ],
        website_url: [
          { validator:function(rule,value,callback){
              if (value != ""){
                if(/^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/.test(value) == false){
                  callback(new Error("请输入正确的Url"));
                }else{
                  callback();
                }
              }else{
                callback();
              }
            }, trigger: 'blur'
          },
        ],
        describe: [
          { required: true, message: '系列描述不能为空', trigger: 'blur' },
        ],
        bg_url: [
          { required: true, message: '系列背景图不能为空', trigger: 'blur' },
        ],
        result_url: [
          { validator:function(rule,value,callback){
              if (value != ""){
                if(/^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/.test(value) == false){
                  callback(new Error("请输入正确的Url"));
                }else{
                    callback();
                }
              }else{
                callback();
              }
              
            }, trigger: 'blur'
          },
        ],
      }
    }
  }
}

export const wishBatchFormRulesMixin = {
  data() {
    return {
      // 添加问答
      addFormRules: {
        art_info_id: [
          { required: true, message: '义链作品不能为空', trigger: 'blur' },
        ],
        start_token_id: [
          { required: true, message: '开始tokenId不能为空', trigger: 'blur' },
        ],
        end_token_id: [
          { required: true, message: '截止tokenId不能为空', trigger: 'blur' },
        ],
        start_date: [
          { required: true, message: '开始日期不能为空', trigger: 'blur' },
        ],
        end_date: [
          { required: true, message: '结束日期不能为空', trigger: 'blur' },
        ],
        valid_quantity: [
          { required: true, message: '最低总票数不能为空', trigger: 'blur' },
        ],
      }
    }
  }
}